import {
  START_PRESENTATION_UPLOAD,
  REPORT_PRESENTATION_UPLOAD_PROGRESS,
  RESET_PRESENTATION_UPLOAD_STATE
} from "constants/actionTypes";
import initialState from "./initialState";

export default (state = initialState.presentationUpload, action) => {
  switch (action.type) {
    case START_PRESENTATION_UPLOAD: {
      const { uploadName, uploadCount, uploadIndex } = action;
      return {...state, totalUploadSize: 0, uploadedSize: 0, activeSection: "upload", uploadName, uploadCount, uploadIndex };
    }
    case REPORT_PRESENTATION_UPLOAD_PROGRESS:
      return {...state, totalUploadSize: action.total, uploadedSize: action.uploaded};
    case RESET_PRESENTATION_UPLOAD_STATE: {
      const { totalUploadSize, uploadedSize, uploadName, uploadCount, uploadIndex } = initialState.presentationUpload;
      return {...state, totalUploadSize, uploadedSize, activeSection: "select", uploadName, uploadCount, uploadIndex };
    }
    default:
      return state;
  }
};
