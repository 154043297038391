export const SHOW_DIALOG = "SHOW_DIALOG";
export const HIDE_DIALOG = "HIDE_DIALOG";
export const MOUNT_DIALOG = "MOUNT_DIALOG";
export const UNMOUNT_DIALOG = "UNMOUNT_DIALOG";

export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";

export const REQUEST_PRESENTATION = "REQUEST_PRESENTATION";
export const RECEIVE_PRESENTATION = "RECEIVE_PRESENTATION";

export const REQUEST_PRESENTATIONS = "REQUEST_PRESENTATIONS";
export const RECEIVE_PRESENTATIONS = "RECEIVE_PRESENTATIONS";
export const DESTROY_PRESENTATION = "DESTROY_PRESENTATION";
export const ADD_NEW_PRESENTATION = "ADD_NEW_PRESENTATION";
export const REPLACE_PRESENTATION = "REPLACE_PRESENTATION";
export const RENAME_PRESENTATION = "RENAME_PRESENTATION";
export const UPDATE_CONVERSION_STATUS = "UPDATE_CONVERSION_STATUS";

export const REQUEST_RECORDS = "REQUEST_RECORDS";
export const RECEIVE_RECORDS = "RECEIVE_RECORDS";
export const ADD_NEW_RECORD = "ADD_NEW_RECORD";

export const REQUEST_USER = "REQUEST_USER";
export const RECEIVE_USER = "RECEIVE_USER";

export const START_PRESENTATION_UPLOAD = "START_PRESENTATION_UPLOAD";
export const REPORT_PRESENTATION_UPLOAD_PROGRESS = "REPORT_PRESENTATION_UPLOAD_PROGRESS";
export const RESET_PRESENTATION_UPLOAD_STATE = "RESET_PRESENTATION_UPLOAD_STATE";

export const RECEIVE_SLIDE = "RECEIVE_SLIDE";
export const RECEIVE_VIDEO = "RECEIVE_VIDEO";
export const REPORT_VIDEO_LOAD_PROGRESS = "REPORT_VIDEO_LOAD_PROGRESS";
export const INCREMENT_CURRENT_SLIDE = "INCREMENT_CURRENT_SLIDE";
export const SAVE_SLIDE_VIDEO_ACTION = "SAVE_SLIDE_VIDEO_ACTION";
export const UPDATE_SLIDE_VIDEO_TIME = "UPDATE_SLIDE_VIDEO_TIME";
export const UPDATE_SLIDE_VIDEO_STATE = "UPDATE_SLIDE_VIDEO_STATE";
export const TOGGLE_RECORDING_PANEL = "TOGGLE_RECORDING_PANEL";
export const TOGGLE_RECORDING_PANEL_LOCK = "TOGGLE_RECORDING_PANEL_LOCK";
export const TOGGLE_SLIDESHOW_MODE = "TOGGLE_SLIDESHOW_MODE";
export const TOGGLE_FULL_SCREEN = "TOGGLE_FULL_SCREEN";
export const UPDATE_RECORDING_STATE = "UPDATE_RECORDING_STATE";
export const UPDATE_RECORDING_TIME = "UPDATE_RECORDING_TIME";
export const START_RECORDING_UPLOAD = "START_RECORDING_UPLOAD";
export const FINISH_RECORDING_UPLOAD = "FINISH_RECORDING_UPLOAD";
export const FAIL_RECORDING_UPLOAD = "FAIL_RECORDING_UPLOAD";
export const REPORT_RECORDING_UPLOAD_PROGRESS = "REPORT_RECORDING_UPLOAD_PROGRESS";
export const SET_RECORDING_UPLOAD_PROGRESS = "REPORT_RECORDING_UPLOAD_PROGRESS";
export const SET_MIC_LABEL = "SET_MIC_LABEL";
export const TOGGLE_NOTES_PANE = "TOGGLE_NOTES_PANE";
export const REPORT_INACTIVE_STREAM = "REPORT_INACTIVE_STREAM";
