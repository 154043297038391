import React from 'react';
import PropTypes from 'prop-types';

const AppLayout = ({children, pageClass, hasFooter = false, hasHeader = false}) => {
  const getContainerClass = () => (
    `page-container ${pageClass || ''} ${hasFooter ? '-has-footer' : ''} ${hasHeader ? '-has-header' : ''}`
  );

  return (
    <div className={getContainerClass()}>
      <div className="page-content">
        {children}
      </div>
    </div>
  );
};

AppLayout.propTypes = {
  children: PropTypes.node,
  pageClass: PropTypes.string,
  hasFooter: PropTypes.bool,
  hasHeader: PropTypes.bool
};

export default AppLayout;
