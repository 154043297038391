import React, { useState, useLayoutEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

// const slotStates = [
//   ["Capture", "in-person", "conference"],
//   ["Record", "in-person", "event"],
//   ["Live stream", "hybrid", "event"],
//   ["Live stream", "virtual", "conference"],
//   ["Record", "in-person", "course"],
//   ["Capture", "in-person", "event"],
//   ["Pre-record", "virtual", "event"],
//   ["Pre-record", "hybrid", "conference"],
// ];
const slotStates = [
  ["Capture",     "in-person", "conference"],
  ["Live stream", "in-person", "conference"],
  ["Live stream", " hybrid  ", "conference"],
  ["Live stream", " virtual ", "conference"],
  ["Pre-record",  " virtual ", "conference"],
  ["Pre-record",  " hybrid  ", "conference"],
  ["Pre-record",  "in-person", "conference"],
  ["Record",      "in-person", "conference"],
];

// Capture your in-person conference
// Live stream your in-person conference
// Live stream your hybrid conference
// Live stream your virtual conference
// Pre-record your virtual conference
// Pre-record your hybrid conference
// Pre-record your in-person conference
// Record your in-person conference


const SLOT_INTERVAL = 2200;
const START_TIMEOUT = 1000;

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 20,
    marginLeft: 2,
    textAlign: "left",
  },
  itemRoot: {
    height: "1.3em",
    lineHeight: '1.3em',
    overflowY: "hidden",
    position: "relative",
    display: 'inline-block',
  },
  item: {
    position: 'absolute',
    top: '0em',
    animation: "$slide 0.8s ease-out",
    height: 50,
    // overflowY: 'hidden'
    animationDelay: "1.42s",
    "animation-iteration-count": 1,
  },
  "@keyframes slide": {
    "0%": {
      top: 0,
      opacity: 1,
    },
    "40%": {
      // top: '-0.3em',
      opacity: 0.1,
    },
    "55%": {
      // top: '-0.6em',
      opacity: 0.1,
    },
    // "55%": {
    //   // top: '-0.9em',
    //   opacity: 0.5,
    // },
    "100%": {
      top: '-1.3em',
      opacity: 1,
    }
  },
}));

const SlotItem = ({ value, nextValue, classes, width = 100 }) => {
  const [key, setKey] = useState(0);

  useLayoutEffect(() => {
    // console.log("item effect", value, nextValue, value === nextValue);
    setKey(x => value === nextValue ? x : x + 1);
  }, [value, nextValue]);

  return (
    <div className={classes.itemRoot} style={{ width }}>
      <span className={classes.item} style={{ animation: value === nextValue ? "none" : undefined }} key={key + 1}>{value}<br />{nextValue}</span>
    </div>
  );
}

const getNextIndex = x => x === slotStates.length - 1 ? 0 : x + 1;

const SlotMachine = () => {
  const [index, setIndex] = useState(0);
  const [started, setStarted] = useState(false);
  const classes = useStyles();

  useLayoutEffect(() => {
    const setNext = () => setIndex(x => getNextIndex(x));
    let interval;
    setTimeout(() => {
      interval = setInterval(setNext, SLOT_INTERVAL);
      setStarted(true);
    }, START_TIMEOUT);
    return () => {
      clearInterval(interval);
    }
  }, []);

  const [item0, item1/* , item2 */] = slotStates[index];
  const [nextItem0, nextItem1/* , nextItem2 */] = slotStates[getNextIndex(index)];
  return (
    <div className={classes.root + " slots"}>
      {started ? <SlotItem value={item0} nextValue={nextItem0} classes={classes} width={250} /> : <div className={classes.itemRoot} style={{ width: 250 }}>{item0}</div>}
      <br />
      <div className={classes.itemRoot} style={{ width: 90 }}>your</div>
      <SlotItem value={item1} nextValue={nextItem1} classes={classes} width={200} />
      <br />
      {/* <SlotItem value={item2} nextValue={nextItem2} classes={classes} width={250} /><br /> */}
      <div className={classes.itemRoot} style={{ width: 250 }}>conference</div>
    </div>
  )
}

export default SlotMachine;