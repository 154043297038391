import React from "react";
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import config from 'config';

function Copyright() {
  return (
    <>
      <br />
      <Typography variant="body2" color="textSecondary" align="center">
        {new Date().getFullYear()}
        {' © Powered by '}{' '}
        <Link color="inherit" href="https://slidespiel.com/">
          SlideSpiel.com
        </Link>
      </Typography>
      <Typography align="center" variant="body2" style={{ color: "#fff" }}>version {config.version}</Typography>
      <br />
    </>
  );
}

export default Copyright;