import React from "react";

import "styles/pages/solutions_page.scss";

import AppLayout from "components/AppLayout";
import PromoHeaderMenu from "~/components/menus/PromoHeaderMenu";
import DemoRequestFormWrapper from "../forms/DemoRequestFormWrapper";
import { solutionsTableData } from "./homeData";
import Copyright from "../spm/Copyright";
// import { Link } from "react-router-dom";

const SolutionsPage = () => {
  return (
    <AppLayout pageClass="pricing-page" hasHeader={false}>
      <header className="pricing-page__header">
        <PromoHeaderMenu />
      </header>
      <section className="pricing-page__main">
        <h1 className="pricing-page__heading">Best Practices</h1>
        <div className="pricing-page__main__text">
          <p>Live streaming or recording your conference sessions allows your business to reach an infinitely larger virtual audience. And to help you begin your event, conference, or online education journey, we’ve compiled some strategies our seasoned clients use to successfully capture their events.</p>
          <h2 className="-color--penxy-green">Verify the demand</h2>
          <p>Live streaming or recording your event is an investment that should generate new revenue and help you build value-added content that grows your audience base.</p>
          <p>The main economic drivers to consider for online participation vs. in-person attendance are:</p>
          <ul className="list list--disc">
            <li>Travel cost<br />
              Traveling to national events can cost $1,000-1,500 or more for travel, lodging, and tickets.  Participating online eliminates most of these overhead expenses for an attendee.</li>
            <li>Accessibility<br />
              Online participation can accommodate busy schedules filled with personal and professional priorities when in person attendance is out of the question.</li>
            <li>Commitment Level<br />
              Newcomers and first-time attendees may be unsure of an event’s benefit or hesitant to commit to the whole event. But online sessions are more flexible and offer a level of freedom to jump off if necessary. This might be more appealing to those people who want to “try it out” first.</li>
          </ul>
          <p>However, online participation doesn’t always bring the same benefit as in-person attendance. You can analyze the value of your event across three categories by reviewing attendee feedback:</p>
          <ul className="list list--disc">
            <li>Education<br />
              “I’ve learnt so much…”, “I’ve never thought of…”, “Session was amazing…”.</li>
            <li>Networking<br />
              “I’ve met people”, “It was great to discuss…”, “I’ve come up with an idea…”.</li>
            <li>Business<br />
              “I found a client/partner/supplier…”, “I’ve stuck a deal”, “I’ve reconnect with a client”.</li>
          </ul>
          <p>If your event is intended for learning, especially for voluntary or mandatory certification, you should strongly consider streaming or recording its sessions.</p>
          <p>With some rare exceptions, we won’t recommend doing that for events that people attend for other purposes. Existing solutions for online networking, idea sharing, or business have been unable to replicate the success of an in-person event. </p>
          <p>While attendee feedback allows you to estimate demand, there might be people who didn’t attend but could have if a virtual option were available. This requires some border scope market research that would include such things as:</p>
          <ul className="list list--disc">
            <li>Market size. How many people are in the industry that you serve?</li>
            <li>Segments. What learning goals are relevant to that industry and how different are those needs depending on specific roles? </li>
            <li>Competition. How do people fulfill their learning needs? What are the alternatives? </li>
            <li>Advantages. How is your offering better than alternatives? For which segments? </li>
            <li>Marketing strategy. What is the best way to reach segments where you have a competitive advantage? </li>
          </ul>
          <p>While conducting such a study is a lot of work, it also promises much higher benefits compared to the conservative approach of focusing on existing attendees and their colleagues. </p>
          <p>We can help you make new or modify existing post-event surveys to extract information, share templates and guide you through the market research.</p>
          {/* <p> <Link to="/new#feedback">Contact</Link> </p> */}
          <h2 className="-color--penxy-green">Concerns</h2>
          <p>Most concerns are related to cannibalization which is the phenomenon where some existing in-person attendance may switch to online without an increase in the total number of participants. This could affect:</p>
          <ul className="list list--disc">
            <li>Registration revenue. The price of the virtual registration may be lower than the in-person one. This way, the total revenue may decline while the expenses increase to pay for the virtual component of the event. </li>
            <li>Hotel room blocking. Many conference organizers commit to the hotel on the minimum number of attendees that will be harder to meet due to lower attendee numbers. </li>
            <li>Expo hall. For some events, the expo hall brings the largest portion of the revenue. While there are some virtual expo hall solutions on the market, they are not performing on the same level as in-person exhibitions and can’t fully replace that experience.</li>
          </ul>
          <p>If you are concerned about attendance cannibalization, there may be a lot of demand for online offerings from your attendance, which is a good sign. These are the strategies to address it:</p>
          <ul className="list list--disc">
            <li>High price for virtual registration. While it is true that in-person attendees get more value from the event, virtual attendees enjoy the flexibility, and comfort of their homes and save a lot on the travel cost. This justifies the high price of virtual registration.</li>
            <li>Geo-restricting online tickets, to be only available for those participants who are outside the acceptable travel range for a particular event and are at a higher risk for not attending in person. </li>
            <li>Bundle online content offerings with in-person participation. For example, if someone from the company, like an executive, attends the event in person, their subordinates can join online.</li>
            <li>Reuse content captured at the event to create new virtual products that are detached from the conference, hence, not competing with it. </li>
          </ul>
          <p>Now, let’s explore the successful strategies that exist, how they make money and mitigate risk of cannibalization.</p>
          <h2 className="-color--penxy-green">Live streaming</h2>
          <p>Joining a live stream of your conference is a great alternative for people who are genuinely interested but can’t attend in person for a myriad of reasons. It offers virtual attendees an in-person-like experience including the ability to ask the speaker questions and communicate with other participants during sessions or in virtual networking rooms. </p>
          <p>For you, it creates a wonderful opportunity to draw a bigger audience and charge a significant virtual registration fee while emphasizing travel savings and convenience as key value propositions. </p>
          <p>Live streaming is easy to integrate into all of your event marketing activities. Including this option from the start will yield the best results instead of waiting until you’ve reached in-person capacity. This ensures your marketing efforts to promote the event have the biggest draw, otherwise interested prospects who are unable to attend in person will simply unsubscribe from event communications.</p>
          <p>We also recommend geo-restricting virtual registrants so you can segment the live stream format only to prospects who are outside of a set travel range. This way the two options compliment each other to increase your overall headcount instead of competing against each other.</p>
          <p>This approach however will require an additional investment into the production and internet bandwidth and you will want a virtual moderator to interact with live streaming participants so they feel involved and engaged. </p>
          <p>Pros:</p>
          <ul className="list list--disc">
            <li>Significant potential revenue.</li>
            <li>Easy to verify a demand.</li>
            <li>Small time investment from the marketing team.</li>
          </ul>
          <p>Cons:</p>
          <ul className="list list--disc">
            <li>Heavy production investment.</li>
            <li>Need to plan ahead of time.</li>
            <li>Significant time investment from the education team to engage virtual attendees.</li>
          </ul>
          <p><strong>Summary:</strong> This is a strategic initiative for international or national conferences with attendees scattered across the globe to significantly increase revenue with minimal marketing or process changes but the heavier production investment requires consideration.</p>
          <h2 className="-color--penxy-green">Conference Recordings</h2>
          <p>Conference recordings are desired by in-person attendees who may have missed a session or want to share one with colleagues. This is especially common at education-centered conferences with multiple sessions running concurrently, because it would be impossible or impractical for learners to attend them all.</p>
          <p>Attendees from the same companies might split the sessions among themselves to make sure that they cover most of the programming and share their notes afterwards. If this is happening at your event, then you should strongly consider recording your conference. </p>
          <p>For you, this is an upsell revenue opportunity that should be available with registration in advance and on-site during the conference. We recommend charging 10%-30% of the registration fee for access to recording files of the entire conference.</p>
          <p>A successful strategy to consider might be to supplement your existing marketing with a few on-site banners on the registration desk and a few announcements at general sessions to raise awareness, especially, if you are doing it for the first time. </p>
          <p>All sessions should be included in the package which raises the scale of recording, but without the need for high quality production. You can take the cost effective approach of recording the speaker’s voice synced with the slideshow - without video of the speaker. </p>
          <p>The Fear of Missing Out (FOMO) on a session fades away very quickly, therefore the recording files must be available within a day or two after the event. Leveraging their initial interest by offering recordings only on-site or in bundles as part of the inflated registration fee lowers the risk of cannibalization. </p>
          <p>Offering the full package of recordings only as an after-thought will yield little return. However, there are some strategies for repackaging recorded content to appeal to prospects who didn’t attend the in-person event that we will discuss below. </p>
          <p>Pros:</p>
          <ul className="list list--disc">
            <li>Low time investment from education team.</li>
            <li>Minor time investment from marketing team.</li>
            <li>Easy to verify a demand.</li>
          </ul>
          <p>Cons:</p>
          <ul className="list list--disc">
            <li>The target audience is limited to existing attendance.</li>
            <li>The price can’t be too high as it is an add-on to in-person participation.</li>
            <li>The content has a short life, meaning you usually won’t be successful trying to sell or market it long-term.</li>
          </ul>
          <p><strong>Summary:</strong> This is an easy way for large educational conferences to ramp up their revenue a bit by capitalizing on the existing attendance. </p>
          <h2 className="-color--penxy-green">Reusing recordings for courses</h2>
          <p>Reusing content from in-person events allows creating online courses that stay relevant for a long time and may be desirable by a wider audience.</p>
          <p>People are willing to pay for online courses that solve specific problems they may be experiencing. However, identifying such problems and the people experiencing them is the key. For example young professionals and seasoned professionals face different challenges. </p>
          <p>Besides running surveys and polls we recommend engaging a group of experts who can help identify problems and segments. Membership based organizations, or associations, are well suited for this task because they know their membership well and already have expert networks.</p>
          <p>Many conferences invite industry experts who are sharing unique data & knowledge during their sessions. These experts rarely create online courses of their own, so a conference could be the only way to access their expertise. Conference and event organizers can leverage recordings of expert sessions to curate & package captured content into courses that solve real problems.</p>
          <p>The value proposition for such a course could focus on the specific guideline posed by an industry expert that is easy to apply to real life vs the general information found online for free.</p>
          <p>When it comes to marketing, membership based organizations and other conference organizers who stay in touch with their audience throughout the year are well positioned to capitalize on this opportunity. Others will have to create a separate process to offer on-demand education products.</p>
          <p>This strategy can be naturally combined with the previous one. Conference organizers are usually recording all sessions as voice recording for attendees and cherry picking a few top expert sessions for video recording that they plan to use for the longer term.</p>
          <p>Risk of cannibalization is almost non-existent here due to content being highly reprocessed.</p>
          <p>Pros:</p>
          <ul className="list list--disc">
            <li>A wide targeted audience and high revenue opportunity</li>
            <li>A clear competitive advantage</li>
            <li>Content stays relevant for a longer term</li>
          </ul>
          <p>Cons:</p>
          <ul className="list list--disc">
            <li>Significant production investment</li>
            <li>Huge time commitment for both marketing and education teams</li>
          </ul>
          <p><strong>Summary:</strong> This is an amazing strategic revenue opportunity for an organization with a strong expert network. </p>
          <h2 className="-color--penxy-green">Continuous content production</h2>
          <p>Creating a steady flow of online content throughout the entire year by producing it piece by piece allows you to follow the needs of your learners more accurately.</p>
          <p>Capturing an in-person event can produce dozens of hours of material while a working professional rarely consumes that much of online education from one source, so some of it can go to waste.</p>
          <p>Alternatively you could produce content continuously throughout the year at small scale in the form of live webinars or pre-recorded courses. This allows you to cover time sensitive issues and adjust your education material on the fly. You can select topics & speakers based on your expert network and from post-conference speaker evaluations from attendees. The biggest challenge, however, is to secure the speaker’s time for the production. </p>
          <p>One thing to note however is that high level experts have high expectations in terms of payments and it can be challenging to get the speaker’s commitment without proper compensation. However, when you invite speakers to the in-person event, there are a lot of non-monetary benefits you can offer as additional perks such as reconnecting with colleagues, networking, fame, etc. </p>
          <p>Recording a course or session in a professional virtual production environment typically delivers content of better quality compared to capturing in-person events. However, some speakers are more comfortable and energetic when presenting in-person, then virtually.</p>
          <p>Pros:</p>
          <ul className="list list--disc">
            <li>Cost efficient vs. capturing in-person events.</li>
            <li>Allows addressing time sensitive issues.</li>
            <li>A wide targeted audience.</li>
          </ul>
          <p>Cons:</p>
          <ul className="list list--disc">
            <li>Hard to get top speakers.</li>
            <li>Significant production investment.</li>
            <li>Huge time commitment for both marketing and education teams.</li>
          </ul>
          <p><strong>Summary:</strong> This strategy is best for addressing time sensitive educational demands by inviting those experts who are easy to engage.</p>
          <h2 className="-color--penxy-green">Chart</h2>
          <div className="pricing-page__table-wrapper">
            <table className="pricing-page__table">
              <thead>
                <tr>
                  {solutionsTableData.header.map(x => <th key={x || '1'}>{x}</th>)}
                </tr>
              </thead>
              <tbody>
                {solutionsTableData.rows.map((row, index) => (
                  <tr key={index}>
                    {row.map((x, index) => <td key={index} style={ index > 0 ? { textAlign: "center" } : {} }>{x}</td>)}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <h2 className="-color--penxy-green">Conclusion</h2>
          <p>Most organizations use a combination of these strategies to scale their events beyond physical walls. Each strategy has its pros and cons and selecting the one that will work best for you will depend on your organization, industry and people you serve. </p>
          <p>SlideSpiel has a lot of experience executing all of these services and we are happy to consult with you to devise the best strategy for your goals. Contact us if you want to have an exploratory conversation with one of our experts. We are happy to help. </p>
          {/* <p>More questions? Talk with one of our experts.</p> */}
          <h2 className="-color--penxy-green">More questions? Talk with one of our experts.</h2>
          <br />
          <DemoRequestFormWrapper gaEvent="lead_solutions" />
        </div>
        <Copyright />
      </section>
    </AppLayout>
  );
};

export default SolutionsPage;
