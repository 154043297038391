export const RTC_ERROR_CODES = {
  NOT_SUPPORTED_BROWSER: -1,
  WEBSOCKET_ERROR: 1,
  ROOM_JOIN_FAILURE: 2,
  MAX_RETRIES_EXCEEDED: 3,
  CONNECTION_FAILED: 4,
}

export const RTC_STATES = {
  ERROR: -1,
  DISCONNECTED: 0,
  CONNECTING: 1,
  CONNECTED: 2,
  JOINING: 3,
  JOINED: 4,
  WAITING: 5,
}

export const LIVE_ERROR_CODES = {
  SOCKET: 1,
  RTC: 2,
  STORAGE: 3,
  BROWSER: 4,
};

export const getTextByState = (state, retries) => {
  switch (state) {
    case RTC_STATES.ERROR: return "Connection Error";
    case RTC_STATES.DISCONNECTED: return "Disconnected";
    case RTC_STATES.CONNECTING: return "Connecting" + (retries ? ", Retry #" + retries : "");
    case RTC_STATES.WAITING: return "Waiting for streams";
    case RTC_STATES.CONNECTED: return "Connected";
    // case RTC_STATES.JOINING: return "Joining";
    // case RTC_STATES.JOINED: return "Connected";
    default: return "Disconnected";
  }
}

export const sessionStatuses = [
  { id: 0, name: "Created", },
  { id: 1, name: "Uploaded", },
  { id: 2, name: "Slides approved", },
  { id: 3, name: "Slides declined", },
  { id: 4, name: "Slides processed", },
  { id: 5, name: "Ready for recording", },
  { id: 6, name: "Recording checked", },
  { id: 7, name: "Recording edited", },
  { id: 9, name: "Recording exported", },
  { id: 8, name: "Ready for delivery", },
];

const manualSteps = [[5], [6], [7], [9], [8]];
export const defaultWorkflow = [[0], [4], ...manualSteps];
export const approvalWorkflow = [[0], [1], [2, 3], [4], ...manualSteps];
export const manualPushWorkflow = [[0], [1], [4], ...manualSteps];

export const workflows = { defaultWorkflow, approvalWorkflow, manualPushWorkflow };

export const getWorkflow = config => config.hasApproval ? workflows.approvalWorkflow : (config.autoPushForSpeakers ? workflows.defaultWorkflow : workflows.manualPushWorkflow);

export const CAMERA_CODES = {
  SCREEN_SHARE: "share_screen",
  NONE: "none",
}