import React, { useState, useEffect } from "react";

// import mainSectionImg from "assets/images/home/main.jpg";
import mainSectionBack from "assets/images/home/back.jpg";
import mainSectionBackLow from "assets/images/home/back-min.jpg";
import "styles/pages/home_page.scss";

import AppLayout from "components/AppLayout";
import PromoHeaderMenu from "~/components/menus/PromoHeaderMenu";
import HomePageSlider from "components/home/HomePageSlider";
import DemoRequestFormWrapper from "components/forms/DemoRequestFormWrapper";
import SlotMachine from "./SlotMachine";
import { Link, /* Button, */ Element, /* Events, animateScroll as scroll, scrollSpy, */ scroller } from 'react-scroll';
import Dialog from "../dialogs/Dialog";
import { clientsData, servicesDetails } from "./homeData";
import Copyright from "../spm/Copyright";
import { Parallax } from 'react-parallax';
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";

export const trackGaEvent = (name, params = {}) => window.gtag('event', name, params);

const LearnMoreButton = ({ ...rest }) => (
  <button className="learn-more" {...rest}>
    <span className="circle" aria-hidden="true">
      <span className="icon arrow"></span>
    </span>
    <span className="button-text">Learn More</span>
  </button>
)

const ServiceItem = ({ index, data, handleDialogOpen }) => {
  const { title, /* subtitle, */ img, text, gaEvent } = data;
  // const imgSrc = useProgressiveImage({ lowQualitySrc: imgLow, highQualitySrc: img, selector: "service_img_" + index });
  return (
    <li>
      <img src={img} alt="icon" className={"service_img_" + index} />
      <h5>{title}</h5>
      {/* <p>{subtitle}</p> */}
      {/* <a className="btn -sq -color--white" href="#" onClick={handleDialogOpen({ title, text })}>Learn More</a> */}
      <LearnMoreButton onClick={handleDialogOpen({ title, text, gaEvent })}>Learn More</LearnMoreButton>
    </li>
  );
}

const useProgressiveImage = ({ lowQualitySrc, highQualitySrc, ref: ref0, selector }) => {
  const [src, setSrc] = useState(lowQualitySrc);

  useEffect(() => {
    // const ref = document.getElementsByClassName("react-parallax-bgimage")[0];
    const ref = ref0 || document.getElementsByClassName(selector)[0];
    ref.onload = () => {
      ref.onload = null;
      const timestamp = Date.now();
      console.log("loaded low bg");
      const image = new Image();
      image.src = highQualitySrc;
      image.onload = () => {
        console.log("loaded full bg", Date.now() - timestamp);
        setSrc(highQualitySrc);
      }
    }
  }, [ref0]);

  return src;
}


const HomePage = () => {
  const [serviceData, setServiceData] = useState(null);
  const [activeReviewIndex, setActiveReviewIndex] = useState(0);
  const [isReviewsInViewport, setIsReviewsInViewport] = useState(false);
  const [isServicesInViewport, setIsServicesInViewport] = useState(false);
  const [isFormInViewport, setIsFormInViewport] = useState(false);
  const theme = createTheme({});
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  // const [bgImage, setBgImage] = useState(mainSectionBackLow);
  const bgImage = useProgressiveImage({ lowQualitySrc: mainSectionBackLow, highQualitySrc: mainSectionBack, selector: "react-parallax-bgimage" });

  const handleLogoClick = index => e => {
    e.preventDefault();
    trackGaEvent("click_review");
    setActiveReviewIndex(index);
  }

  const handleDialogOpen = item => e => {
    e.preventDefault();
    trackGaEvent(item.gaEvent);
    setServiceData(item);
  }

  // useEffect(() => {
  //   const ref = document.getElementsByClassName("react-parallax-bgimage")[0];
  //   ref.onload = () => {
  //     ref.onload = null;
  //     const timestamp = Date.now();
  //     // console.log("loaded low bg");
  //     const image = new Image();
  //     image.src = mainSectionBack;
  //     image.onload = () => {
  //       console.log("loaded full bg", Date.now() - timestamp);
  //       setBgImage(mainSectionBack);
  //     }
  //   }
  // }, []);

  useEffect(() => {
    const { hash } = window.location;
    if (!hash)
      return;

    setTimeout(() => scroller.scrollTo(hash.replace("#", ""), {
      duration: 1000,
      delay: 0,
      smooth: true,
      offset: -70, // Scrolls to element + 50 pixels down the page
    }), 500);
  }, []);

  useEffect(() => {
    if (isReviewsInViewport)
      trackGaEvent("view_clients");
  }, [isReviewsInViewport]);

  useEffect(() => {
    if (isServicesInViewport)
      trackGaEvent("view_services");
  }, [isServicesInViewport]);

  useEffect(() => {
    if (isFormInViewport)
      trackGaEvent("view_contact_us");
  }, [isFormInViewport]);

  // useEffect(() => {
  //   const timeout = setTimeout(() => setActiveReviewIndex(i => i === clientsData.length - 1 ? 0 : i + 1), 1_000);
  //   return () => {
  //     clearTimeout(timeout);
  //   }
  // }, [activeReviewIndex]);

  const bgImageStyle = { marginTop: 250, opacity: 0.5, height: "auto", width: "100%" };
  let strength = -400;
  if (isMd) {
    bgImageStyle.marginTop = 100;
    strength = -200;
  }
  if (isSm) {
    bgImageStyle.marginTop = 0;
    bgImageStyle.width = "110%";
    bgImageStyle.height = undefined;
    strength = 200;
  }
  if (isXs) {
    strength = 400;
    // bgImageStyle.marginTop = 10;
    bgImageStyle.width = "auto";
    bgImageStyle.height = "90vh";
    bgImageStyle.left = "unset";
    bgImageStyle.right = 0;
    bgImageStyle.translate = "50%";
  }

  // console.log({ isMd, isSm, isXs });
  return (
    <ThemeProvider theme={theme}>
      <div>
        <header className="home-page__header">
          <AppLayout pageClass="home-page" hasHeader={false}>
            <PromoHeaderMenu />
          </AppLayout>
        </header>
        <div>
          {serviceData &&
            <Dialog isVisible actions={{ hideDialog: () => setServiceData(null), showDialog: () => { } }}>
              <h2 style={{ whiteSpace: "pre-wrap", marginTop: 5, fontWeight: 500 }}>{serviceData.title}</h2>
              <p style={{ fontSize: 17, whiteSpace: "pre-wrap", color: "#333" }}>{serviceData.text}</p>
            </Dialog>
          }
          <AppLayout pageClass="home-page" hasHeader={false}>
            <section className="home-page__main1">
              {/* <img className="home-page__main__img" src={mainSectionImg} alt="cool pic" /> */}
              <Parallax bgImage={bgImage} strength={strength} blur={0} bgImageStyle={bgImageStyle}>
                <div className="page-content">
                  {/* <Parallax blur={10} bgImage="path/to/image.jpg" bgImageAlt="the cat" strength={200}> */}
                  <div className="home-page__main__text1">
                    <SlotMachine />
                    <div className="home-page__main__buttons">
                      <Link className="btn -sq -color--white" to="services" spy={false} smooth={true} offset={-70} duration={500} onClick={() => trackGaEvent("click_learn_more")}>
                        Learn More
                      </Link>
                      <Link className="btn -sq -color--white" to="feedback" spy={false} smooth={true} offset={-70} duration={1000} onClick={() => trackGaEvent("click_get_in_touch")}>
                        Get a Quote
                      </Link>
                      {/* <a className="btn -sq -color--white" href="#services">Learn More</a> */}
                      {/* <a className="btn -sq -color--white" href="#feedback">Get in Touch</a> */}
                    </div>
                  </div>
                </div>
              </Parallax>
              {/* <img className="home-page__main__img home-page__main__img--desktop" src={mainSectionImg} alt="cool pic" /> */}
            </section>
            <Link to="reviews" offset={-30} onSetActive={() => setIsReviewsInViewport(true)} onSetInactive={() => setIsReviewsInViewport(false)} spy={true} activeClass="asd" />
            <Element name="reviews">
              <section className="home-page__reviews" id="reviews">
                <div className="home-page__customers__heading">Trusted by 80+ conference organizers in the US and EU</div>
                <ul className="home-page__customers__list list">
                  {clientsData.map((item, index) => (
                    <li className={"home-page__customers__list__item box--vertical" + ((activeReviewIndex === index || !isReviewsInViewport) ? " active" : "")} key={item.link}>
                      <a href={"#reviews-" + item.link} onClick={handleLogoClick(index)}>
                        <img src={item.logo} alt={item.org} style={{ transform: `scale(${item.scale || 1})` }} />
                      </a>
                    </li>
                  ))}
                </ul>
                {/* <div className="home-page__reviews__heading">What customers say about us:</div> */}
                <HomePageSlider activeReviewIndex={activeReviewIndex} setActiveReview={setActiveReviewIndex} />
              </section>
            </Element>
            <Link to="services" offset={-30} onSetActive={() => setIsServicesInViewport(true)} onSetInactive={() => setIsServicesInViewport(false)} spy={true} activeClass="asd" />
            <Element name="services">
              <section className="home-page__services">
                <div className="home-page__services__heading">Our innovative approach</div>
                <div className="home-page__services__sub-heading">SlideSpiel delivers amazing results at an affordable cost as our revolutionary platform efficiently automates the entire production process</div>
                <ul className="home-page__services__list list">
                  {servicesDetails.map((data, index) => (
                    <ServiceItem key={index} data={data} index={index} handleDialogOpen={handleDialogOpen} />
                  ))}
                </ul>
              </section>
            </Element>
            <Link to="feedback" offset={-30} onSetActive={() => setIsFormInViewport(true)} onSetInactive={() => setIsFormInViewport(false)} spy={true} activeClass="asd" />
            <Element name="feedback">
              <section className="home-page__feedback" id="feedback">
                <div className="home-page__feedback__heading">Let’s talk about your event and organization</div>
                <br />
                <br />
                {/* <div className="home-page__feedback__sub-heading">{"Let's learn more about each other."}</div> */}
                <DemoRequestFormWrapper gaEvent="lead_main" />
              </section>
            </Element>
            <Copyright />
          </AppLayout>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default HomePage;
