import React, { useState } from "react";

import AppLayout from "components/AppLayout";
import PromoHeaderMenu from "components/menus/PromoHeaderMenu";
import DemoRequestFormWrapper from "components/forms/DemoRequestFormWrapper";
import Copyright from "../spm/Copyright";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { createTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles';
import "styles/pages/pricing_page.scss";
import HelpIcon from '@material-ui/icons/HelpOutline';
import PlayIcon from '@material-ui/icons/PlayArrow';
import Tooltip from '@material-ui/core/Tooltip';
import { FeedbackProvider, useFeedback } from "../feedback/Service";
import Api from "../Api";
import { trackGaEvent } from "./HomePage";

const useStyles = makeStyles(theme => ({
  tooltip: {
    fontSize: '1rem',
    whiteSpace: 'pre-line',
    lineHeight: 1.5,
    maxWidth: 500
  },
  icon: {
    marginLeft: theme.spacing(0.5),
    top: 5,
    position: "relative"
  },
  tabWrapper: {
    display: "inline",
  },
  sampleRoot: {
    position: "relative",
    cursor: "pointer",
    minHeight: 180,
    "& > svg": {
      position: "absolute",
      top: '50%',
      left: '50%',
      transform: "translate(-50%, -50%)",
      fontSize: "5rem",
      // background: alpha("#fff", 0.7),
      background: theme.palette.primary.main,
      color: "#fff",
      borderRadius: 100,
      padding: 5,
    }
  },
  iframeWrapper: {
    width: "100%",
    // paddingBottom: "56.2%",
    // minHeight: 300,
    // padding: "0 !important",
    background: "#000",
    position: "relative",
    "& > iframe": {
      position: "absolute",
      width: "100%",
      height: "100%",
    }
  }
}));

const tabs = [
  { id: 0, title: "In-Person Events", gaEvent: "lead_pricing_inperson", badgeText: "In-person event is the event with a physical location where attendees come to participate. Having said that, in-person event can have a few virtual speakers or a significant number of virtual attendees." },
  { id: 1, title: "Virtual Events", gaEvent: "lead_pricing_virtual", badgeText: "Virtual event has no physical location and all attendees are virtual, joining from their devices." },
  { id: 2, title: "Website & Content Hosting", gaEvent: "lead_pricing_website" },
];

const HelpBadge = ({ children }) => {
  const classes = useStyles();
  return (
    <Tooltip title={children || ""} classes={{ tooltip: classes.tooltip }}><HelpIcon className={classes.icon} style={children ? undefined : { visibility: "hidden" }} color="primary" /></Tooltip>
  );
}

const InPersonTable = () => (
  <div className="pricing-page__table-wrapper">
    <table className="pricing-page__table">
      <thead>
        <tr>
          <th></th>
          <th>Voice of the speaker & Slideshow</th>
          <th>Video of the speaker & Slideshow</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={{ textAlign: "left" }}>Recording</td>
          <td style={{ textAlign: "left" }}>$100 / 1-hour session</td>
          <td style={{ textAlign: "left" }}>$200 / 1-hour session</td>
        </tr>
        <tr>
          <td style={{ textAlign: "left" }}>Live streaming</td>
          <td style={{ textAlign: "left" }}></td>
          <td style={{ textAlign: "left" }}>$300 / 1-hour session</td>
        </tr>
        <tr>
          <td style={{ textAlign: "left" }}>Staff</td>
          <td style={{ textAlign: "left" }}>
            $400 / day per person
            <HelpBadge>One person from our team can handle voice & slideshow session recording in 3-4 concurrent rooms.</HelpBadge>
          </td>
          <td style={{ textAlign: "left" }}>$700 / day per person</td>
        </tr>
        <tr>
          <td style={{ textAlign: "left" }}>Travel<HelpBadge>Client provides a hotel room for the duration of the project.</HelpBadge></td>
          <td style={{ textAlign: "left" }}>$300 / person</td>
          <td style={{ textAlign: "left" }}>$300 / person</td>
        </tr>
        <tr>
          <td style={{ textAlign: "left" }}>Sample</td>
          <td style={{ textAlign: "left" }}><Sample previewUrl="/api/presentation/049dacd2-a0a0-4458-9af7-119dfcd6843d/preview" src="https://slidespiel.com/view/fydy" ratio="56.3%"/></td>
          <td style={{ textAlign: "left" }}><Sample previewUrl="/api/presentation/54c5ba84-d21e-4e7c-86e8-45689185f709/preview" src="https://slidespiel.com/view/seda" ratio="28.1%"/></td>
        </tr>
      </tbody>
    </table>
  </div>
);

const VirtualTable = () => (
  <div className="pricing-page__table-wrapper">
    <table className="pricing-page__table">
      <thead>
        <tr>
          <th></th>
          <th>Video of the speaker & Slideshow</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={{ textAlign: "left" }}>
            DIY Pre-Recording
            <HelpBadge>
              We provide speakers with easy to use web app and simple instructions, so they can record the session when it is convenient.<br />
              It is best for speakers with some experience with computers.
            </HelpBadge></td>
          <td style={{ textAlign: "left" }}>$100 / 1-hour session</td>
        </tr>
        <tr>
          <td style={{ textAlign: "left" }}>
            Supervised Pre-Recording
            <HelpBadge>
              We schedule a time with the speaker and our specialist guides them through the whole recording process.<br />
              It is best for speakers who lack computer proficiency.
            </HelpBadge>
          </td>
          <td style={{ textAlign: "left" }}>$200 / 1-hour session</td>
        </tr>
        <tr>
          <td style={{ textAlign: "left" }}>Live Virtual Sessions<HelpBadge></HelpBadge></td>
          <td style={{ textAlign: "left" }}>
            $200-300 / 1-hour session
            <HelpBadge>
              1 speaker – $200 / 1-hr of session<br />
              2+ speakers – $300 / 1-hr of session
            </HelpBadge>
          </td>
        </tr>
        <tr>
          <td style={{ textAlign: "left" }}>Staff<HelpBadge>Virtual conference staff typically includes a virtual conference manager (who mostly communicates with speakers) and dedicated attendee support manager (who mostly communicates with attendees).</HelpBadge></td>
          <td style={{ textAlign: "left" }}>$300 / day per person</td>
        </tr>
        <tr>
          <td style={{ textAlign: "left" }}>Sample</td>
          <td style={{ textAlign: "left" }}><Sample previewUrl="/api/presentation/b1e07ad7-f4a2-4708-98ab-d4212f34ac93/preview" src="https://slidespiel.com/view/tixyf" ratio="28.1%" /></td>
        </tr>
      </tbody>
    </table>
  </div>
);

const WebsiteTable = () => (
  <div className="pricing-page__table-wrapper">
    <table className="pricing-page__table wrap">
      <tbody>
        <tr>
          <td>
            Core website<br />
            $1,000/year
          </td>
          <td>
            <ul>
              <li>Branding</li>
              <li>Custom URL</li>
              <li>Login & password for users</li>
              <li>Landing and home pages, schedule, library, etc. </li>
              <li>An access management tool for admins</li>
              <li>User support via email</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>
            Registration<br />
            $1,000
          </td>
          <td>
            Free or paid registration with ability to collect additional information from users.
          </td>
        </tr>
        <tr>
          <td>
            Single Sign-On<br />
            $1,000 - $2,000<HelpBadge>Depending on the API complexity of your existing user management database.</HelpBadge>
          </td>
          <td>
            If you already have a users database (i.e. via AMS or registration system), we can connect to it, so users will be using their existing login and password to access the website.
          </td>
        </tr>
        <tr>
          <td>
            On-demand Education Library<br />
            $1,000/year
          </td>
          <td>
            A library with education content available on-demand with search bar and filters for easy navigation.
          </td>
        </tr>
        <tr>
          <td>
            Continuing Education Tracking & Certificates<br />
            $1,000-$3,000
          </td>
          <td>
            Implementing continuing education tracking & certification procedure. It can include:
            <ul>
              <li>Tracking the session viewing time</li>
              <li>Adding tests before or after session to verify learning outcome</li>
              <li>Automatically generating and emailing CE certificates based on certain criteria. </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>
            Virtual Exhibit Hall<br />
            $100-200 per exhibitor
          </td>
          <td>
            A dedicated area for exhibitors and sponsors to share their information with attendees. Attendees can talk to them via chat, video or voice. The exhibit hall can be clearly marked and separated from educational sessions.
          </td>
        </tr>
        <tr>
          <td>
            Virtual Networking Rooms<br />
            $100/day/room
          </td>
          <td>
            Up to 12 attendees can join into a single virtual networking room during live virtual events to have a conversation. You host multiple networking rooms.
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

const Sample = ({ previewUrl, src, ratio }) => {
  const feedback = useFeedback();
  const classes = useStyles();

  const handleShowRecording = () => {
    trackGaEvent("view_sample");
    feedback.alert({ fullWidth: true, confirmButtonText: "Close", maxWidth: "md", clickAwayClose: true, component: <div className={classes.iframeWrapper} style={{ paddingBottom: ratio }}><iframe src={src} allowFullScreen /></div> })
  }

  return (
    <div className={classes.sampleRoot} onClick={handleShowRecording}>
      <img src={Api.makeAssetUrl(previewUrl)} />
      <PlayIcon color="primary" />
    </div>
  )
}

const PricingPage = () => {
  const [tab, setTab] = useState(tabs[0])
  const handleTabChange = (e, id) => setTab(tabs.find(x => x.id === id));
  const classes = useStyles();

  const palette = {
    type: "light",
    primary: {
      main: "#609928",
    },
  };
  const theme = createTheme({ palette });

  return (
    <ThemeProvider theme={theme}>
      <FeedbackProvider>
        <AppLayout pageClass="pricing-page" hasHeader={false}>
          <header className="pricing-page__header">
            <PromoHeaderMenu />
          </header>
          <section className="pricing-page__main">
            <h1 className="pricing-page__heading">Pricing</h1>
            <Tabs
              value={tab.id}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              {tabs.map(tab => (
                <Tab classes={{ wrapper: classes.tabWrapper }} label={<><span>{tab.title}</span><HelpBadge>{tab.badgeText}</HelpBadge></>} value={tab.id} key={tab.id} />
              ))}
            </Tabs>
            <div className="pricing-page__main__text">
              {tab.id === 0 &&
                <>
                  {/* <h2>
                  In-Person Events
                  <HelpBadge>{tab.badgeText}</HelpBadge>
                </h2> */}
                  <p>Our staff travels to the location of your event, brings their equipment and records or live streams your conference sessions. </p>
                  <InPersonTable />
                </>
              }
              {tab.id === 1 &&
                <>
                  {/* <h2>
                  In-Person Events
                  <HelpBadge>{tab.badgeText}</HelpBadge>
                </h2> */}
                  <p>We provide a virtual event platform and staff to manage it. We communicate with your speakers, attendees and help you to run the conference. </p>
                  <VirtualTable />
                </>
              }
              {tab.id === 2 &&
                <>
                  {/* <h2>
                  In-Person Events
                  <HelpBadge>{tab.badgeText}</HelpBadge>
                </h2> */}
                  <p>SlideSpiel can set up a website to host live events or on-demand education.</p>
                  <WebsiteTable />
                </>
              }
            </div>
          </section>
          <section className="pricing-page__feedback">
            <h2 style={{ textAlign: "center", marginBottom: 40 }}>Would you like to get an accurate quote?</h2>
            {/* <div className="pricing-page__heading">Would you like to get an accurate quote?</div> */}
            {/* <h4>Would you like to get an accurate quote?</5> */}
            {/* <div className="pricing-page__sub-heading">Leave your email address or contact us.</div> */}
            <div>
              {/* <div className="pricing-page__feedback__form"> */}
              <DemoRequestFormWrapper gaEvent={tab.gaEvent} />
              {/* </div> */}
            </div>
          </section>
          <Copyright />
        </AppLayout>
      </FeedbackProvider>
    </ThemeProvider>
  );
};

export default PricingPage;
