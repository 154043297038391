import * as types from 'constants/actionTypes';
import initialState from './initialState';

export default (state = initialState.modal, action) => {
  switch (action.type) {
    case types.SHOW_DIALOG:
      return {...state, visible: true};
    case types.HIDE_DIALOG:
      return {...state, visible: false};
    case types.MOUNT_DIALOG:
      return {...state, current: action.dialog};
    case types.UNMOUNT_DIALOG:
      return {...state, current: null, visible: false};
    default:
      return state;
  }
};
