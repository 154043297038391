import { useEffect, useRef } from 'react';
import moment from "moment";
import DetectRTC from 'detectrtc';
import { RTC_STATES, LIVE_ERROR_CODES } from './spm/dictionary';
import config from '../config';

export const bindAll = (object, ...methods) => {
  for (let i = 0; i < methods.length; i++) {
    const method = methods[i];
    object[method] = object[method].bind(object);
  }
};

export const formatISODate = (isoDateStr) => {
  const dateObj = new Date(isoDateStr),
    months = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"],
    month = months[dateObj.getMonth()];
  return `${dateObj.getDate()} ${month} ${dateObj.getFullYear()}`;
};

export const parseJsonSilently = (jsonStr) => {
  try {
    return JSON.parse(jsonStr);
  } catch (e) {
    return null;
  }
};

export const throttle = (func, limit) => {
  let inThrottle;

  return function () {
    const args = arguments,
      context = this;

    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => inThrottle = false, limit);
    }
  }
};

export const debounce = (func, delay) => {
  let timeout;

  return function () {
    const functionCall = () => func.apply(this, arguments);

    clearTimeout(timeout);
    timeout = setTimeout(functionCall, delay);
  }
};

export const recentTime = (date, recentThreshold, roundToMinutes = false) => {
  const seconds = Math.floor((new Date() - date) / 1000);

  if (isNaN(seconds))
    return "unknown";

  if (seconds < recentThreshold) {
    return "just now";
  }

  let interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
    return interval + "d ago";
  }

  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    return interval + "h ago";
  }

  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    return interval + "m ago";
  }

  if (roundToMinutes)
    return "less than 1m ago";
  return Math.floor(seconds) + "s ago";
};

export const uniq = (arr) => {
  return arr.filter((value, index, self) => self.indexOf(value) === index);
};

export const getTimeString = (timeStamp) => {
  const d = new Date(timeStamp);

  let hours = d.getUTCHours(),
    minutes = d.getMinutes(),
    seconds = d.getSeconds(),
    ms = d.getMilliseconds();

  let result = "";

  if (minutes < 10) minutes = "0" + minutes;
  if (seconds < 10) seconds = "0" + seconds;

  result = minutes + ':' + seconds + (config.showMs ? ('.' + ms) : "");

  if (hours > 0) {
    result = hours + ':' + result;
  }

  return result;
};

export const connectionStateToString = (state) => {
  switch (state) {
    case 0:
      return "connecting";
    case 1:
      return "connected";
    case 2:
      return "reconnecting";
    case 4:
      return "disconnected";
    default:
      return "unknown";
  }
};

export const addParamToUrl = (url, param, value) => {
  const qsPart = [param, value].map(encodeURIComponent).join("=");
  if (url.indexOf("?") === -1) {
    const urlEnd = url.slice(-1);
    return `${url}${urlEnd === "/" ? "" : "/"}?${qsPart}`;
  }
  return `${url}&${qsPart}`;
};

const VIEW_PATH = 'https://slidespiel.com/view/';

export const copyToClipboard = (text) => {
  var dummy = document.createElement("textarea");
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.innerHTML = text;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
}

export const getRecordViewPath = () => VIEW_PATH;

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export const formatDateInterval = (startDateU, endDateU) => {
  const startDate = moment(startDateU);
  const endDate = moment(endDateU);
  if (!startDateU && endDateU)
    return "till " + endDate.format("LL");
  if (!endDateU || endDate.format("LL") === startDate.format("LL"))
    return startDate.format("LL");
  if (startDate.month() === endDate.month())
    return `${startDate.format("MMMM D")} – ${endDate.format("D, YYYY")}`;
  return moment(startDate).format("MMMM D") + " – " + moment(endDate).format("LL");
}

export const formatSpeakerName = speaker => {
  return (speaker.title ? speaker.title + " " : "") + (speaker.firstName ? speaker.firstName + " " : "") + (speaker.lastName || "") + (speaker.degree ? ", " + speaker.degree : "")
}

const localStorageEnabled = () => {
  try {
    localStorage.setItem("lsTest", "test");
    return true;
  }
  catch {
    return false;
  }
}

export const getLiveError = (receiver, rtcConnection, error, streamer) => {
  // console.log(receiver?.state);
  if (!DetectRTC.isWebRTCSupported)
    return LIVE_ERROR_CODES.BROWSER;
  if (rtcConnection?.state === RTC_STATES.ERROR)
    return LIVE_ERROR_CODES.SOCKET;
  if (!localStorageEnabled())
    return LIVE_ERROR_CODES.STORAGE;
  if (receiver?.state === RTC_STATES.ERROR || streamer?.state === RTC_STATES.ERROR)
    return LIVE_ERROR_CODES.RTC;
  return error;
}

export function msToTime(s, showMs = false) {
  // Pad to 2 or 3 digits, default is 2
  var pad = (n, z = 2) => ('00' + n).slice(-z);
  return pad(s / 3.6e6 | 0) + ':' + pad((s % 3.6e6) / 6e4 | 0) + ':' + pad((s % 6e4) / 1000 | 0) + (showMs ? '.' + pad(s%1000, 3) : "");
}

export const generateGuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0,
      v = c === 'x' ? r : ((r & 0x3) | 0x8);
    return v.toString(16);
  });
};

export const getScrollBarStyle = theme => ({
  "&::-webkit-scrollbar": {
    backgroundColor: "#fff",
    width: theme.spacing(2),
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#fff",
  },
  "&::-webkit-scrollbar-track:hover": {
    backgroundColor: theme.palette.grey[100],
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.grey[600],
    borderRadius: 16,
    border: "5px solid #fff"
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: theme.palette.grey[700],
    border: "5px solid " + theme.palette.grey[300],
  },
  "&::-webkit-scrollbar-button": {
    display: "none",
  },
});

export const wait = (func, timeout) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      func().then(resolve).catch(reject);
    }, timeout);
  });
}

export function getVideoDimensionsOf(url) {
  return new Promise(resolve => {
    // create the video element
    const video = document.createElement('video');

    // place a listener on it
    video.addEventListener("loadedmetadata", function () {
      // retrieve dimensions
      const height = this.videoHeight;
      const width = this.videoWidth;

      // send back result
      resolve({ height, width });
    }, false);

    // start download meta-data
    video.src = url;
  });
}

export function getMediaDurationOf(url) {
  return new Promise(resolve => {
    // create the video element
    const video = document.createElement('video');

    // place a listener on it
    video.addEventListener("loadedmetadata", function () {
      // retrieve duration
      const duration = this.duration;

      // send back result
      resolve(duration);
    }, false);

    // start download meta-data
    video.src = url;
  });
}

export function getImageDimensionsOf(url) {
  return new Promise(resolve => {
    // create the video element
    const img = document.createElement('img');

    // place a listener on it
    img.addEventListener("load", function () {
      // retrieve dimensions
      const { naturalHeight: height, naturalWidth: width } = this;

      // send back result
      resolve({ height, width });
    }, false);

    // start download meta-data
    img.src = url;
  });
}

export function readAsArrayBuffer(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(blob);
    reader.onloadend = () => { resolve(reader.result); };
    reader.onerror = (ev) => { reject(ev.error); };
  });
}