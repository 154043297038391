import {
  REQUEST_RECORDS,
  RECEIVE_RECORDS,
  ADD_NEW_RECORD
} from "constants/actionTypes";
import initialState from "./initialState";

export default (state = initialState.records, action) => {
  switch (action.type) {
    case REQUEST_RECORDS:
      return {...state, isFetching: true};
    case RECEIVE_RECORDS:
      return {...state, isFetching: false, items: [...state.items, ...action.items], continueWith: action.continueWith};
    case ADD_NEW_RECORD:
      return {...state, items: [action.item, ...state.items]};
    default:
      return state;
  }
};
