import { combineReducers } from 'redux';

import user from './userReducer';
import modal from './dialogReducer';
import currentUser from './currentUserReducer';
import records from './recordsReducer';
import presentations from './presentationsReducer';
import presentationUpload from './presentationUploadReducer';
import recordingPage from './recordingPageReducer';

export default combineReducers({
  user,
  modal,
  currentUser,
  records,
  recordingPage,
  presentations,
  presentationUpload
});
