import {
  REQUEST_PRESENTATIONS,
  RECEIVE_PRESENTATIONS,
  DESTROY_PRESENTATION,
  ADD_NEW_PRESENTATION,
  REPLACE_PRESENTATION,
  RENAME_PRESENTATION,
  UPDATE_CONVERSION_STATUS
} from "constants/actionTypes";
import initialState from "./initialState";

export default (state = initialState.presentations, action) => {
  switch (action.type) {
    case REQUEST_PRESENTATIONS:
      return {...state, isFetching: true};
    case RECEIVE_PRESENTATIONS:
      return {...state, isFetching: false, items: [...state.items, ...action.items], continueWith: action.continueWith};
    case ADD_NEW_PRESENTATION:
      return {...state, items: [action.item, ...state.items]};
    case UPDATE_CONVERSION_STATUS: {
      const newConversions = {...state.conversions};
      newConversions[action.contentId] = {status: action.conversionStatus, details: action.conversionDetails};
      return {...state, conversions: newConversions};
    }
    case REPLACE_PRESENTATION: {
      const itemIndex = state.items.findIndex((item) => item.contentId === action.contentId);

      if (itemIndex === -1) {
        console.warn(REPLACE_PRESENTATION, "item with contentId", action.contentId, "not found");
        return state;
      }

      const newItems = [...state.items];
      newItems.splice(itemIndex, 1, action.presentation);

      return {...state, items: newItems};
    }
    case DESTROY_PRESENTATION: {
      const newItems = state.items.filter((item) => item.contentId !== action.contentId);
      return {...state, items: newItems};
    }
    case RENAME_PRESENTATION: {
      const newItems = state.items.map((item) => {
        if (item.contentId === action.contentId) {
          return {...item, name: action.name};
        }
        return item;
      });
      return {...state, items: newItems};
    }
    default:
      return state;
  }
};
