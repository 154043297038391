import React from "react";

import "styles/pages/pricing_page.scss";

import AppLayout from "components/AppLayout";
import PromoHeaderMenu from "~/components/menus/PromoHeaderMenu";
import Copyright from "../spm/Copyright";

const AboutPage = () => {
  return (
    <AppLayout pageClass="pricing-page" hasHeader={false}>
      <header className="pricing-page__header">
        <PromoHeaderMenu />
      </header>
      <section className="pricing-page__main">
        <h1 className="pricing-page__heading">About</h1>
        <div className="pricing-page__main__text">
          <p>SlideSpiel, founded by Vova Platov, is an innovative streaming and recording company utilized by over 500 conferences and events throughout the US and EU since its 2017 debut.</p>
          <p>Conference and event organizers play an important role in facilitating professional conversations in the industries they serve by matching the best specialists with the most important topics. SlideSpiel helps them expand their reach online by recording or live streaming their events. </p>
          <p>We leverage cutting-edge technology and cloud infrastructure to automate content production and deliver the edited recordings files as quickly as 30 minutes after the event - all for half the price of traditional session capturing services.</p>
          <p>SlideSpiel is committed to the consistent development of its platform and services in line with event industry changes and to support our client’s goals. </p>
        </div>
        <br />
        <br />
        <br />
        <Copyright />
      </section>
    </AppLayout>
  );
};

export default AboutPage;
