const ScriptCache = {
  scripts: [],

  load(url) {
    if (this.scripts.indexOf("url") === -1) {
      this.scripts.push(url);
      this.appendScript(url);
    }
  },

  appendScript(url) {
    const script = document.createElement("script");
    script.src = url;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }
};

export default ScriptCache;
