import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SnackbarMessage({ open, handleClose, text, type = 'info', autoHide = true }) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={autoHide ? 5000 : null}
      onClose={autoHide ? handleClose : undefined}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      style={{ zIndex: 9999 }}
    >
      <Alert onClose={handleClose} severity={type}>
        {text}
      </Alert>
    </Snackbar>
  );
}
