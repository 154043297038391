import React, { PureComponent, Fragment } from "react";

import scriptCache from "components/scriptCache";

import DemoRequestForm from "components/forms/DemoRequestForm";
import { trackGaEvent } from "../pages/HomePage";
import getApi from "../spm/api";
import config from "~/config";

class DemoRequestFormWrapper extends PureComponent {
  state = {
    isSubmitDisabled: false,
    requestSucceeded: false,
    error: false
  };

  componentDidMount() {
    // scriptCache.load("https://www.google.com/recaptcha/api.js");
    scriptCache.load("https://www.google.com/recaptcha/api.js?render=" + config.captchaSiteKey);
  }

  handleSubmit = (registrationData) => {
    this.setState({
      isSubmitDisabled: true,
      error: false
    });

    getApi().demoRequest({ ...registrationData, source: this.props.gaEvent }).then(() => {
      trackGaEvent(this.props.gaEvent || "lead", { value: 100, currency: "USD" });
      this.setState({
        isSubmitDisabled: false,
        requestSucceeded: true,
        error: false
      });
    }, (error) => {
      console.error({ error });
      this.setState({
        isSubmitDisabled: false,
        error: error.response?.data || "Unknown error"
      });
    });

    // window.fbq("track", "Lead");
  };

  render() {
    return (
      <Fragment>

        {this.state.requestSucceeded ?
          <div className="demo-request__success -text-center">
            <h2>Success! Please check your email.</h2>
            <p>Someone from our team will email you in next 24 hours to set up an introductory call! We appreciate your interest.</p>
            <br />
            <br />
            <br />
          </div> :
          <DemoRequestForm
            onSubmit={this.handleSubmit}
            isSubmitDisabled={this.state.isSubmitDisabled}
            error={this.state.error}
          />
        }
      </Fragment>
    );
  }
}

export default DemoRequestFormWrapper;
