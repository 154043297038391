import { LOGIN_USER, LOGOUT_USER } from 'constants/actionTypes';
import initialState from './initialState';

export default (state = initialState.currentUser, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {...state, ...action.userData};
    case LOGOUT_USER:
      return {};
    default:
      return state;
  }
};
