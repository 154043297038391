import React, { PureComponent } from "react";
import arrow from "assets/images/home/slider_arrow.svg";
import { clientsData } from "../pages/homeData";
import Grid from "@material-ui/core/Grid";
import { trackGaEvent } from "../pages/HomePage";

class HomePageSlider extends PureComponent {
  state = {
    activeReview: 0,
  };

  componentDidUpdate(prevProps) {
    if (this.props.activeReviewIndex !== prevProps.activeReviewIndex)
      this.setState({ activeReview: this.props.activeReviewIndex });
  }

  handleNextClick = (e) => {
    trackGaEvent("click_review");
    const increment = parseInt(e.target.getAttribute("data-increment"), 10);

    let nextReview = this.state.activeReview + increment;
    if (nextReview >= clientsData.length) {
      nextReview = 0;
    } else if (nextReview < 0) {
      nextReview = clientsData.length - 1;
    }

    this.setState({ activeReview: nextReview });
    this.props.setActiveReview(nextReview);
  };

  handleNavClick = (e) => {
    const index = parseInt(e.target.getAttribute("data-index"), 10);
    this.setState({ activeReview: index });
    trackGaEvent("click_review");
  };

  render() {
    return (
      <div className="home-page__slider">
        <div className="home-page__slider__body">
          <div className="home-page__slider__list">
            <div className="home-page__slider__list__wrap" style={{ left: `${-this.state.activeReview * 100}%` }}>
              {clientsData.map((review, i) => (
                <div key={i} className={`home-page__slider__item ${i === this.state.activeReview ? "home-page__slider__item--active" : ""} box--vertical`}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={1} />
                    <Grid item xs={12} md={3}>
                      {review.image &&
                        <img src={review.image} alt="manager" className="home-page__slider__item__photo" />
                      }
                      <div className="home-page__slider__item__author">{review.author}</div>
                      <div className="home-page__slider__item__title">{review.title}</div>
                      <div className="home-page__slider__item__org">{review.org}</div>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ display: "flex", alignItems: "center" }}>
                      <p className="home-page__slider__item__body">{review.body}</p>
                    </Grid>
                    <Grid item xs={12} md={1} />
                  </Grid>
                </div>
              ))
              }
            </div>
          </div>
          <button type="button" className="home-page__slider__arrow home-page__slider__arrow--left btn" onClick={this.handleNextClick} data-increment="-1" style={{ background: `url(${arrow}) center center no-repeat` }} />
          <button className="home-page__slider__arrow btn" onClick={this.handleNextClick} data-increment="1" style={{ background: `url(${arrow}) center center no-repeat` }} />
        </div>
        <nav className="home-page__slider__nav">
          <ul className="list -text-center">
            {clientsData.map((review, i) => (
              <li key={i} className={`home-page__slider__nav__item ${i === this.state.activeReview ? "home-page__slider__nav__item--active" : ""} box--inline`} onClick={this.handleNavClick} data-index={i} />
            ))
            }
          </ul>
        </nav>
      </div>
    );
  }
}

// HomePageSlider.reviewsData = [
//   {
//     image: sliderNBOAmg,
//     body: "SlideSpiel always knocks it out of the park recording our Annual Meeting sessions. They’re extremely affordable and easy to work with. Their value add far surpasses the cost of their services. We’ve worked with other recording providers, and SlideSpiel excels on quality and ease of implementation. If you’re looking to record a live event, SlideSpiel is the way to go.",
//     author: "Melissa Zinder",
//     title: "Senior Manager, Marketing and Member Engagement",
//     org: "National Business Officers Association (NBOA)",
//     link: "nboa"
//   }, {
//     //   image: sliderRFMAImg,
//     //   body: "We researched content capture of our conference sessions for years, but the example of the product was never worth the price tag to make it happen. They provided us a cost-effective solution and were amazingly easy to work with and the files were in my inbox when I returned from my conference. Very pleased with the process.",
//     //   author: "Debi Kensell",
//     //   title: "Director Education",
//     //   org: "The Restaurant Facility Management Association (RFMA)",
//     //   link: "rfma"
//     // }, {
//     //   image: sliderABAImg,
//     //   body: "The SlideSpiel team jumped in to our event, supported our staff, and worked with our speakers and presenters as though they had been part of our organization for years. I was blown away by their professionalism, resourcefulness, and enthusiasm for making our event and its recordings the best they could be.",
//     //   author: "Greg Galloway",
//     //   title: "Director",
//     //   org: "American Booksellers Association (ABA)",
//     //   link: "aba"
//     // }, {
//     image: sliderAVMAImg,
//     body: "AVMA has worked with SlideSpiel over the last four years. They are easy to work with and very accommodating. When we needed to switch and go to virtual, they were able to help us record our speakers ahead of time and get them edited for the platform. When we have been in-person, they offer different opportunities for recording that allow us to make it work for our needs. Their pricing is very reasonable and their platform is easy to use. I would highly recommend using SlideSpiel for any of your recording needs.",
//     author: "Amber Auge",
//     title: "Continuing Education Manager | Convention & Meeting Planning Division",
//     org: "American Veterinary Medical Association (AVMA)",
//     link: "avma"
//   }, {
//     image: sliderIaapaImg,
//     body: "Working with Slidespiel was a very cost effective and efficient process that allowed us to deploy a full, and robust virtual event in 8 short weeks. The platform backend is intuitive, and easy to use which allowed us to get to market so quickly.",
//     author: "Reno Deschaine",
//     title: "Vice President, Global Education and Member Services",
//     org: "IAAPA",
//     link: "iaapa"
//   }, {
//     //   image: sliderIntermountainImg,
//     //   body: "SlideSpiel has been a pleasure to work with. They have been responsive and were able to help pivot three of our major conferences to their virtual platform within a few months. A few major reasons I choose to work with them is the display of the presenter/presentation side by side, they take care of 100% of the tech support during and leading up to the conference, and are able to facilitate a completely remote faculty smoothly from one location to the other. I plan on continuing to work with them into 2021 with more conferences moving to virtual",
//     //   author: "Amanda Kirk",
//     //   title: "Accredited Education Coordinator",
//     //   org: "Intermountain Healthcare",
//     //   link: "intermountain"
//     // }, {
//     image: sliderAimcalImg,
//     body: "Slidespiel has proven to be an excellent resource for creating and hosting video content for our virtual events. With an easy to use platform and extremely fast technical support, Slidespiel is a reliable and cost effective solution for your video content needs",
//     author: "Kevin Lifsey",
//     title: "Web Developer",
//     org: "AIMCAL",
//     link: "aimcal"
//   }, {
//     image: sliderNAAImg,
//     body: "The process of recording our sessions at our annual Conference and Show was so much easier and saved our association 50% over previous years. SlideSpiel was extremely easy to use so there was very little training required for personnel. Also, the recordings were available within minutes of the conclusion of the session, rather than having to wait nearly 2 months before receiving them previously. The staff at SlideSpiel, were tremendously helpful before, during and after the event.",
//     author: "Aaron Ensminger",
//     title: "Director of Education",
//     org: "National Auctioneers Association (NAA)",
//     link: "naa"
//   }, {
//     image: sliderMECCImg,
//     body: "Our experience with SliedSpiel has been absolutely fabulous. Slidespiel team are highly professional, reliable and with excellent outcome. Their response time and ability to accommodate to the unique circumstances of our events is highly appreciated.",
//     author: "Teri Valls",
//     title: "Executive Director",
//     org: "Meetings Events Conference Coordinators (MECC)",
//     link: "mecc"
//   }, {
//     //   image: sliderUCAImg,
//     //   body: "UCA had a great experience from start to finish with SlideSpiel! The onsite staff was very professional and ability to get the finished product out and delivered within a couple days far exceeded my expectations.",
//     //   author: "Carla Jamison",
//     //   title: "Director Education",
//     //   org: "Urgent Care Association (UCA)",
//     //   link: "uca"
//     // }, {
//     image: sliderADHAImg,
//     body: "ADHA chose SlideSpiel as a partner for our first ever conference recording and we couldn't have been happier! The onsite help at the show was tremendous and the quality of the recordings is phenomenal! Our show ended on Sunday and by Tuesday I had all of the recordings downloaded at the office. We'll certainly work with SlideSpiel again for future session recording.",
//     author: "Stephen Legault",
//     title: "Manager, Professional Development",
//     org: "American Dental Hygienists' Association (ADHA)",
//     link: "adha"
//   }, {
//     //   body: "Vova's team videorecorded approximately 8 hours of content from the general sessions at our January 2019 Annual Meeting. They were great to work with and we were very happy with the finished product.",
//     //   author: "David Robinson",
//     //   title: "Director of Membership and Meetings",
//     //   org: "Linguistic Society of America (LSA)",
//     //   link: "lsa"
//     // }, {
//     body: "SlideSpiel provided exactly what was promised. The site was easy to use. Recordings are great, when panelists follow instructions. Delivery was on time as guaranteed. We are so pleased with our first experience. SlideSpiel delivered!",
//     author: "Cindy Amos",
//     title: "Vice President Meetings",
//     org: "Transportation Intermediaries Association (TIA)",
//     link: "tia"
//   }];

export default HomePageSlider;
