import { REQUEST_USER, RECEIVE_USER } from 'constants/actionTypes';
import initialState from './initialState';

export default (state = initialState.user, action) => {
  switch (action.type) {
    case REQUEST_USER:
      return {...state, isFetching: true};
    case RECEIVE_USER:
      return {...state, isFetching: false, attributes: action.data};
    default:
      return state;
  }
};
