// TODO: data normalization
export default {
  currentUser: {
    isFetching: true,
  },
  modal: {
    current: null,
    visible: false,
  },
  presentations: {
    isFetching: false,
    continueWith: null,
    items: [],
    conversions: {}
  },
  records: {
    isFetching: false,
    continueWith: null,
    items: []
  },
  user: {
    isFetching: false,
    attributes: {}
  },
  presentationUpload: {
    activeSection: "select", // "select", "upload"
    totalUploadSize: 0,
    uploadedSize: 0,
    uploadName: "",
    uploadIndex: 0,
    uploadCount: 0
  },
  recordingPage: {
    presentation: {
      isFetching: false,
      attributes: {}
    },
    slides: {
      isFetching: false,
      loaded: []
    },
    videos: {
      loaded: {},
      loadProgress: [],
      loadedSize: 0
    },
    currentSlide: 0,
    isPanelLocked: false,
    isPanelHidden: false,
    isSlideshowMode: false,
    isFullScreen: false,
    recordingState: null, // null, "recording", "saving"
    savingState: null, // null, saving, failed, saved
    timeline: [],
    recordingTime: 0,
    uploadTotalSize: 0,
    uploadedSize: 0,
    micLabel: null,
    streamState: null,
    isNotePaneVisible: false,
    slideVideoState: null, // null, "play", "pause"
    slideVideoTime: 0
  }
};
