import efficiencyImg from "assets/images/home/efficiency.png";
import launchingImg from "assets/images/home/launching.png";
import usabilityImg from "assets/images/home/usability.png";
import analyticsImg from "assets/images/home/analytics.svg";
import efficiencyImgLow from "assets/images/home/efficiency-min.png";
import launchingImgLow from "assets/images/home/launching-min.png";
import usabilityImgLow from "assets/images/home/usability-min.png";

import nboaLogo from "assets/images/customers/nboa_logo.png";
import avmaLogo from "assets/images/customers/avma_logo.svg";
import taaoLogo from "assets/images/customers/taao_logo.png";
import meccLogo from "assets/images/customers/mecc_logo.png";
import njsopLogo from "assets/images/customers/njsop_logo.jpeg";
import wmccaiLogo from "assets/images/customers/wmccai_logo.png";
import iaapaLogo from "assets/images/customers/iappa_logo.jpeg";
import aemLogo from "assets/images/customers/aem_logo.jpeg";
import aimcalLogo from "assets/images/customers/aimcal_logo.jpeg";

import nboaPhoto from "assets/images/customers/nboa_photo.jpg";
import avmaPhoto from "assets/images/customers/avma_photo.jpg";
import taaoPhoto from "assets/images/customers/taao_photo.png";
import meccPhoto from "assets/images/customers/mecc_photo.jpeg";
import njsopPhoto from "assets/images/customers/njsop_photo.jpeg";
import wmccaiPhoto from "assets/images/customers/wmccai_photo.jpeg";
import iaapaPhoto from "assets/images/customers/iaapa_photo.png";
import aemPhoto from "assets/images/customers/aem_photo.jpeg";
import aimcalPhoto from "assets/images/customers/aimcal_photo.jpeg";

export const clientsData = [
  {
    logo: iaapaLogo,
    image: iaapaPhoto,
    body: "Working with SlideSpiel was a very cost effective and efficient process that allowed us to deploy a full, and robust virtual event in 8 short weeks. The platform backend is intuitive, and easy to use which allowed us to get to market so quickly.",
    author: "Reno Deschaine",
    title: "Vice President, Global Education and Member Services",
    org: "The International Association of Amusement Parks and Attractions",
    link: "iaapa",
    scale: 1.2
  }, {
    logo: nboaLogo,
    image: nboaPhoto,
    body: "SlideSpiel always knocks it out of the park recording our Annual Meeting. They’re extremely affordable and easy to work with. Their value add far surpasses the cost of their services. We’ve worked with other recording providers, and SlideSpiel excels on quality. If you’re looking to record a live event, SlideSpiel is the way to go.",
    author: "Melissa Zinder",
    title: "Senior Manager, Marketing and Member Engagement",
    org: "National Business Officers Association",
    link: "nboa",
    scale: 1.2
  }, {
    logo: avmaLogo,
    image: avmaPhoto,
    body: "AVMA has worked with SlideSpiel over the last four years. They are easy to work with and very accommodating. They offer different opportunities for recording that allow us to make it work for our needs. Their pricing is very reasonable and their platform is easy to use. I would highly recommend using SlideSpiel for any of your recording needs.",
    author: "Amber Auge",
    title: "Continuing Education Manager | Convention & Meeting Planning Division",
    org: "American Veterinary Medical Association",
    link: "avma"
  }, {
    logo: taaoLogo,
    image: taaoPhoto,
    body: `TAAO just completed our 83rd Annual Conference. This was the 2nd year we have partnered with SlideSpiel for recording the conference sessions. Their team is affordable, professional, reliable, experienced, easy to work with and they demonstrate a willingness to go the extra mile to get the job done. They recorded 29 sessions without a single issue. They house the recordings on the website. I will use SlideSpiel again and again! Great Job!`,
    author: "Zelda Cryar",
    title: "Executive Director",
    org: "Texas Association of Assessing Officers",
    link: "taao"
  }, {
    logo: aemLogo,
    image: aemPhoto,
    body: "As an event and conference director I would not hesitate to recommend SlideSpiel. The team was easy to work with and they could quickly pivot with us onsite when schedules changed or programs were adjusted. We received our quality product files in a timely fashion and there was never a lapse in communication.",
    author: "Brooke Konopacki",
    title: "Director, Education Programs",
    org: "Association of Equipment Manufacturers",
    link: "aem",
    scale: 0.9
  }, {
    logo: meccLogo,
    image: meccPhoto,
    body: "Our experience with SlideSpiel has been absolutely fabulous. Vova and his team are highly professional, reliable and with excellent outcome. Their response time and ability to accommodate to the unique circumstances of our events is highly appreciated.",
    author: "Teri Valls",
    title: "Executive Director",
    org: "Meetings Events Conference Coordinators",
    link: "mecc",
    scale: 1.5
  }, {
    logo: njsopLogo,
    image: njsopPhoto,
    body: "Our company has been utilizing Slide Spiel to manage our online education portal since 2019.  In addition, we have hired them to host a few of our live webinars and to also record sessions on-site at our live continuing education events.  We are satisfied with their service and expertise, as well as their ability to quickly identify and address our specific needs.",
    author: "Sharon L. Kais",
    title: "Associate Executive Director",
    org: "New Jersey Society of Optometric Physicians",
    link: "njsop"
  }, {
    logo: wmccaiLogo,
    image: wmccaiPhoto,
    body: "Building an on-demand video library website for our membership seemed like a daunting task . We needed to design a visually appealing, user-friendly, easily updated site on which our members could seamlessly purchase, view and receive credit for courses.  SlideSpiel helped us do just that at a reasonable cost!  Their staff has been very responsive and helpful with any issues.  We have a clean, well-maintained, branded site that we are very proud of – thank you!",
    author: "Suzanne White",
    title: "Educational Programs",
    org: "Washington Metropolitan Chapter Community Associations Institute",
    link: "wmccai",
    scale: 1.1
  }, {
    logo: aimcalLogo,
    image: aimcalPhoto,
    body: "SlideSpiel has proven to be an excellent resource for creating and hosting video content for our virtual events. With an easy to use platform and extremely fast technical support, SlideSpiel is a reliable and cost effective solution for your video content needs.",
    author: "Kevin Lifsey",
    title: "Web Developer",
    org: "The Association of International Metallizers, Coaters and Laminators",
    link: "aimcal",
    scale: 0.7
  },
];

export const servicesDetails = [
  {
    title: "Cost Efficiency",
    subtitle: "Cost efficient. No need to ship and rent expensive equipment",
    img: efficiencyImg,
    imgLow: efficiencyImgLow,
    gaEvent: "click_learn_more_cost_efficiency",
    text: `Traditional content-capturing companies rely on expensive hardware operated by multiple technicians.

We leverage our software solution that cuts the cost by 20%-40% by eliminating equipment rental & shipment costs, significantly reducing the number of on-site technicians.

It integrates onsite computers, basic AV equipment, and speaker presentations into a smart ecosystem that can be managed from the tech table or even remotely from our office. `
  },
  {
    title: "Quick Turnaround",
    subtitle: "We are proud to be the only company that can deliver edited recording files minutes after the session ends",
    img: launchingImg,
    imgLow: launchingImgLow,
    gaEvent: "click_learn_more_quick_turnaround",
    text: `We are proud of our ability to deliver edited recording files within minutes after each session ends.

Our software syncs slideshow presentations with a speaker’s voice and video in real time. In the background it uploads the file to our servers, where it is automatically cleaned and by AI and, finally, polished by our video editors.

Optionally, we set up an online learning website to house the content and auto-generate continuing education certificates based on activity tracking against specific metrics. `
  },
  {
    title: "Higher Quality",
    subtitle: "Higher quality recording with less staff due to remote quality control",
    img: usabilityImg,
    imgLow: usabilityImgLow,
    gaEvent: "click_learn_more_higher_quality",
    text: `Inevitably, unexpected problems will arise which is why quality control practices are so critical. Traditional methods require an on-site technician to monitor the recording quality in real-time. But having a dedicated specialist in every room is expensive. And floating technicians who monitor multiple rooms jeopardize the quality of the final product because their attention is fragmented.

Our platform allows us to monitor quality from our office using our remote control tools and resolve simple issues immediately while alerting on-site crew for complicated ones. This process is more efficient, costs significantly less than traditional methods, and is easy to track, scale, and manage.`
  },
  {
    title: "Reliable Virtual Conferences",
    subtitle: "Seamless / Reliable Virtual (& Hybrid) Conferences / Production",
    img: analyticsImg,
    imgLow: analyticsImg,
    gaEvent: "click_learn_more_reliable_virtual_conferences",
    text: `Human error or equipment malfunction contributes to most virtual or hybrid conference issues. Yet our multilevel quality control system mitigates this risk even before the speaker goes live. We have implemented a 30-second automated test for every speaker to ensure everything is working properly. We also follow up directly with anyone likely to experience an issue during a live event. 
    
On the day of the event, speakers join a virtual “backstage” or “green room” to interact with our production team, verify audio and video quality, and provide any as-needed instructions. Our proprietary software allows us to resolve common equipment problems like selecting the correct microphone and playback device which is an added convenience for non-tech-savvy speakers. Once finished, we remotely activate the live session without any further action from the speaker. 
    
Our pre-recording service is resilient to video freezes and distortions that could result from slow internet connection on the speaker end. Our platform saves the recording file on the user's computer and then uploads it on our servers in the highest quality.`
  },
];

export const solutionsTableData = {
  header: ["", "Live streaming", "Conference recording", "Courses from recordings", "Continues production"],
  rows: [
    ["Targeted Segment", "Membership & Followers", "Regular Attendees", "Wider Audience", "Wider Audience"],
    ["Revenue opportunity", "Medium", "Low", "High", "Medium"],
    ["Monetary investment", "High", "Medium", "High", "Low"],
    ["Time investment", "Medium", "Low", "High", "High"],
    ["Marketing", "Easy", "Medium", "Hard", "Hard"],
  ]
}