import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "moment";
import "moment-duration-format";

import React from 'react';
import { createRoot } from 'react-dom/client';

import Root from './components/Root';
import configureStore from './store/configureStore';
import * as serviceWorker from './serviceWorker';

import './styles/styles.scss';
import config from "./config";


const store = configureStore();

console.log("[SLIDESPIEL]", "version", config.version);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Root store={store} />);

serviceWorker.unregister();
