import React, { useState } from "react";
import { Link } from 'react-scroll'
import siteLogoImg from "assets/images/logo.svg";
import { useLocation, NavLink as RouterLink } from "react-router-dom";

const PromoHeaderMenu = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const handleToggleMenuClick = () => setIsMenuVisible(x => !x);
  const { pathname } = useLocation();
  return (
    <nav className="promo-header-menu -clear">
      <RouterLink to="/">
        <img src={siteLogoImg} alt="site logo" className="promo-header-menu__logo" />
      </RouterLink>
      <button className={`promo-header-menu__trigger btn ${isMenuVisible ? "promo-header-menu__trigger--close" : ""}`} type="button" onClick={handleToggleMenuClick} />
      <ul className="promo-header-menu__list_new list" style={{ display: isMenuVisible ? "block" : "none" }}>
        <li className="promo-header-menu__item">
          <RouterLink to="/about" activeClassName="active">About</RouterLink>
        </li>
        <li className="promo-header-menu__item">
          <RouterLink to="/pricing" activeClassName="active">Pricing</RouterLink>
        </li>
        <li className="promo-header-menu__item">
          <RouterLink to="/solutions" activeClassName="active">Best Practices</RouterLink>
        </li>
        {!isMenuVisible &&
          <li className="promo-header-menu__item">
            |
          </li>
        }
        {pathname === "/" ?
          <>
            <li className="promo-header-menu__item">
              <Link activeClass="active" to="reviews" spy={true} smooth={true} offset={-100} duration={500} onClick={() => setIsMenuVisible(false)}>
                Clients
              </Link>
            </li>
            <li className="promo-header-menu__item">
              <Link activeClass="active" to="services" spy={true} smooth={true} offset={-100} duration={500} onClick={() => setIsMenuVisible(false)}>
                Services
              </Link>
            </li>
            <li className="promo-header-menu__item">
              <Link activeClass="active" to="feedback" spy={true} smooth={true} offset={-200} duration={500} onClick={() => setIsMenuVisible(false)}>
                Contact Us
              </Link>
            </li>
          </> :
          <>
            <li className="promo-header-menu__item">
              <RouterLink to="/#reviews" activeClassName="active1">Clients</RouterLink>
            </li>
            <li className="promo-header-menu__item">
              <RouterLink to="/#services" activeClassName="active1">Services</RouterLink>
            </li>
            <li className="promo-header-menu__item">
              <RouterLink to="/#feedback" activeClassName="active1">Contact Us</RouterLink>
            </li>
          </>}
      </ul>
    </nav>
  );
}

export default PromoHeaderMenu;
