import {createStore, compose, applyMiddleware} from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';

import rootReducer from 'reducers';

export default (initialState) => {
  const middlewares = [
    reduxImmutableStateInvariant()
  ];

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose,
        enchancer = composeEnhancers(applyMiddleware(...middlewares)),
        store = createStore(rootReducer, initialState, enchancer);

  // eslint-disable-next-line
  if (process.env.NODE_ENV !== "production") {
    window.store = store;
  }

  return store;
};
