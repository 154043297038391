import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { bindAll } from "components/Utils";

class Dialog extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    footer: PropTypes.element,
    actions: PropTypes.object,
    isVisible: PropTypes.bool,
    canClose: PropTypes.bool,
    children: PropTypes.node
  };

  static defaultProps = {
    canClose: true
  };

  constructor(...args) {
    super(...args);
    bindAll(this, "handleEscPress", "handleBackdropClick");
  }

  componentDidMount() {
    setTimeout(this.props.actions.showDialog, 0);
    document.addEventListener("keyup", this.handleEscPress, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.handleEscPress, false);
  }

  handleEscPress(e) {
    if (e.which === 27 && this.props.canClose) {
      this.props.actions.hideDialog();
    }
  }

  handleBackdropClick() {
    if (this.props.canClose) {
      this.props.actions.hideDialog();
    }
  }

  getContainerClassName() {
    const cls = ["modal", "-fade"];
    this.props.isVisible && cls.push("-in");
    this.props.className && cls.push(this.props.className);
    return cls.join(" ");
  }

  render() {
    return (
      <div className={this.getContainerClassName()}>
        <div className="modal__backdrop" onClick={this.handleBackdropClick} />
        <div className="modal__dialog" style={{ ...this.props.style }}>
          <div className="modal__content">
            <div className="modal__body">{this.props.children}</div>
            {this.props.footer &&
              <div className="modal__footer">{this.props.footer}</div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default Dialog;
