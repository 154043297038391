import {
  REQUEST_PRESENTATION,
  RECEIVE_PRESENTATION,
  RECEIVE_SLIDE,
  RECEIVE_VIDEO,
  REPORT_VIDEO_LOAD_PROGRESS,
  INCREMENT_CURRENT_SLIDE,
  SAVE_SLIDE_VIDEO_ACTION,
  TOGGLE_RECORDING_PANEL,
  TOGGLE_RECORDING_PANEL_LOCK,
  TOGGLE_SLIDESHOW_MODE,
  TOGGLE_FULL_SCREEN,
  UPDATE_RECORDING_STATE,
  UPDATE_RECORDING_TIME,
  START_RECORDING_UPLOAD,
  FINISH_RECORDING_UPLOAD,
  FAIL_RECORDING_UPLOAD,
  REPORT_RECORDING_UPLOAD_PROGRESS,
  SET_MIC_LABEL,
  TOGGLE_NOTES_PANE,
  UPDATE_SLIDE_VIDEO_TIME,
  UPDATE_SLIDE_VIDEO_STATE,
  REPORT_INACTIVE_STREAM
} from "constants/actionTypes";
import initialState from "./initialState";

export default (state = initialState.recordingPage, action) => {
  switch (action.type) {
    case REQUEST_PRESENTATION: {
      const newPresentationState = {...state.presentation, isFetching: true};
      return {...state, presentation: newPresentationState};
    }
    case RECEIVE_PRESENTATION: {
      const newPresentationState = {...state.presentation, isFetching: true, attributes: action.item};
      return {...state, presentation: newPresentationState};
    }
    case RECEIVE_SLIDE: {
      const newLoadedSlidesState = [...state.slides.loaded, action.slideNo],
            isFetching = newLoadedSlidesState.length === action.slidesTotal,
            newSlidesState = {...state.slides, isFetching, loaded: newLoadedSlidesState};
      return {...state, slides: newSlidesState};
    }
    case RECEIVE_VIDEO: {
      const newLoadedVideosState = {...state.videos.loaded},
            newVideosState = {...state.videos, loaded: newLoadedVideosState};

      action.slidesInterval.forEach((slideNo) => {
        if (slideNo in newLoadedVideosState) {
          newLoadedVideosState[slideNo]++;
        } else {
          newLoadedVideosState[slideNo] = 1;
        }
      });

      return {...state, videos: newVideosState};
    }
    case REPORT_VIDEO_LOAD_PROGRESS: {
      const newloadProgress = [...state.videos.loadProgress];
      newloadProgress[action.videoIndex] = action.loadedSize;

      const loadedSize = newloadProgress.reduce((res, p) =>  res + p),
            newVideosState = {...state.videos, loadedSize, loadProgress: newloadProgress};

      return {...state, videos: newVideosState};
    }
    case INCREMENT_CURRENT_SLIDE: {
      if (!state.presentation.attributes || state.recordingState === "saving")
        return state;

      let newCurrentSlide = state.currentSlide + action.step;
      if (newCurrentSlide < 0) {
        newCurrentSlide = 0;
      } else if (newCurrentSlide > state.presentation.attributes.slideCount - 1) {
        newCurrentSlide = state.presentation.attributes.slideCount - 1;
      }

      let newState = {...state, currentSlide: newCurrentSlide, slideVideoTime: 0, slideVideoState: "pause"};
      if (state.recordingState === "recording") {
        const newTimeline = [...state.timeline];
        // don't pop first slide
        if (state.timeline.length > 1 && state.recordingTime - state.timeline[state.timeline.length - 1].time < 1) {
          newTimeline.pop();
        }
        newTimeline.push({time: state.recordingTime, slideNo: newCurrentSlide});
        newState.timeline = newTimeline;
      }
      return newState;
    }
    case SAVE_SLIDE_VIDEO_ACTION: {
      if (state.recordingState !== "recording")
        return state;

      const newTimeline = [...state.timeline, {
        time: state.recordingTime,
        slideNo: state.currentSlide,
        videoIndex: action.videoIndex,
        videoAction: action.videoAction
      }];

      return {...state, timeline: newTimeline};
    }
    case UPDATE_SLIDE_VIDEO_TIME:
      return {...state, slideVideoTime: action.videoTime};
    case UPDATE_SLIDE_VIDEO_STATE:
      return {...state, slideVideoState: action.videoState};
    case TOGGLE_RECORDING_PANEL:
      if (state.isPanelLocked) return state;
      return {...state, isPanelHidden: !action.isOn};
    case TOGGLE_RECORDING_PANEL_LOCK:
      return {...state, isPanelLocked: action.isOn, isPanelHidden: false};
    case TOGGLE_SLIDESHOW_MODE:
      return {...state, isSlideshowMode: action.isOn};
    case TOGGLE_FULL_SCREEN:
      return {...state, isFullScreen: action.isOn};
    case UPDATE_RECORDING_STATE: {
      let newState = {...state, recordingState: action.val};
      if (state.recordingState === null && action.val === "recording") { // recording started
        newState.timeline = [{time: 0, slideNo: state.currentSlide}];
        newState.recordingTime = 0;
      }
      return newState;
    }
    case UPDATE_RECORDING_TIME:
      return {...state, recordingTime: action.val};
    case START_RECORDING_UPLOAD:
      return {...state, savingState: "saving", uploadTotalSize: 0, uploadedSize: 0};
    case FINISH_RECORDING_UPLOAD:
      return {...state, savingState: "saved"};
    case FAIL_RECORDING_UPLOAD:
      return {...state, savingState: "failed", uploadTotalSize: 0, uploadedSize: 0};
    case REPORT_RECORDING_UPLOAD_PROGRESS:
      return {...state, uploadTotalSize: action.total, uploadedSize: action.uploaded};
    case SET_MIC_LABEL:
      return {...state, micLabel: action.val};
    case REPORT_INACTIVE_STREAM:
      return {...state, streamState: "inactive"};
    case TOGGLE_NOTES_PANE:
      return {...state, isNotePaneVisible: !state.isNotePaneVisible};
    default:
      return state;
  }
};
