import React from "react";
import PropTypes from "prop-types";
import { BeatLoader } from "react-spinners";
import config from "~/config";

const DemoRequestForm = ({ onSubmit, isSubmitDisabled, error }) => {

  const getCaptcha = () => {
    return new Promise((resolve, reject) => {
      window.grecaptcha.ready(function () {
        window.grecaptcha.execute(config.captchaSiteKey, { action: 'submit' }).then(function (token) {
          console.log({ token });
          resolve(token);
        }).catch(reject);
      });
    });
  }

  const submitHandler = async (e) => {
    e.preventDefault();

    const token = await getCaptcha();
    const formElements = e.target.elements;
    const fields = ["fullName", "jobTitle", "company", "email", "phone", "comments", "captcha"];

    const data = fields.reduce((res, fieldName) => {
      if (fieldName === "fullName") {
        const [firstName, lastName] = formElements[fieldName].value.split(" ");
        res["firstName"] = firstName;
        res["lastName"] = lastName;
      } else if (fieldName === "captcha") {
        res["captcha"] = token;
      } else {
        res[fieldName] = formElements[fieldName].value;
      }
      return res;
    }, {});

    onSubmit(data);
  };

  return (
    <form onSubmit={submitHandler} className="demo-request__form">
      <input type="text" name="fullName" placeholder="Name*" required className="demo-request__input" autoComplete="full-name" />
      <input type="email" name="email" placeholder="Work Email*" required className="demo-request__input" autoComplete="email" />
      <input type="text" name="jobTitle" placeholder="Job Title*" required className="demo-request__input" autoComplete="organization-title" />
      <input type="tel" name="phone" placeholder="Phone Number*" required className="demo-request__input" autoComplete="tel" />
      <input type="text" name="company" placeholder="Organization*" required className="demo-request__input" autoComplete="organization" />
      <textarea name="comments" placeholder="Message" className="demo-request__comments" />
      {error &&
        <div className="demo-request__error -text-center">
          <h3 className="-color--red">{error}</h3>
          <p>Please try again.</p>
        </div>
      }
      <div className="demo-request__buttons -text-center">
        {isSubmitDisabled ?
          <BeatLoader color="#609928" /> :
          <button className="btn -sq -color--white" type="submit" disabled={isSubmitDisabled}>Submit</button>}
      </div>
    </form>
  );
};

DemoRequestForm.propTypes = {
  isSubmitDisabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired
};

export default DemoRequestForm;
